import { LaunchRounded } from "@mui/icons-material";
import { Avatar, Link, Stack, Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";

import useDoc from "../hooks/useDoc";
import type { User } from "../types/user.db";
import { formatPostalCode } from "../util/stdlib";

import { PartitionedCard, PartitionedCardItem } from "./PartitionedCard";

function UserCard({ userId }: { userId: string | null }): JSX.Element {
  const [user, loading] = useDoc<User>("users", userId);
  const phoneNumber = user?.phone ? parsePhoneNumber(user.phone, "US") : null;
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${user?.postal_code}`;

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (user?.timestamps.created_at as Timestamp)?.toDate();

  return (
    <PartitionedCard
      avatar={
        <Avatar
          alt={user?.name}
          src={user?.photo_url ?? ""}
          sx={{ width: 32, height: 32 }}
        />
      }
      title={
        user ? (
          <Link href={`/users/${user?.id}`} color={"inherit"}>
            {user?.name ?? "User"}
          </Link>
        ) : (
          <>&nbsp;</>
        )
      }
      spacing={4}
      loading={loading}
    >
      {user?.email && (
        <PartitionedCardItem title={"Email"}>
          <Typography>
            {user?.email ? (
              <Link href={"mailto:" + user?.email}>{user?.email}</Link>
            ) : (
              "None"
            )}
          </Typography>
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Phone"}>
        <Typography>{phoneNumber?.formatNational() ?? "None"}</Typography>
      </PartitionedCardItem>

      <PartitionedCardItem title={"Address"}>
        <Link href={mapsUrl} target={"_blank"}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography>
              {user?.postal_code
                ? user?.state + ", " + formatPostalCode(user?.postal_code)
                : "None"}
            </Typography>
            <LaunchRounded fontSize={"inherit"} />
          </Stack>
        </Link>
      </PartitionedCardItem>

      <PartitionedCardItem title={"Joined"}>
        {createdAt ? (
          <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
            </Typography>
          </Tooltip>
        ) : (
          <Typography>None</Typography>
        )}
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default UserCard;
