import { Avatar, Chip, Grid, Stack, Typography } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import type { Timestamp } from "firebase/firestore";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useFirebase from "../hooks/useFirebase";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import useQuery from "../hooks/useQuery";
import type { SavedState } from "../types/navigate";
import type { User } from "../types/user.db";
import { formatPostalCode } from "../util/stdlib";

import StatusChip from "./subscriptions/StatusChip";

const columns: GridColumns<User> = [
  {
    field: "user",
    headerName: "User",
    ...columnTypes.flexPrimary,
    minWidth: 200,
    renderCell: (params) =>
      params.row && (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Avatar
            src={params.row.photo_url ?? ""}
            sx={{ width: 24, height: 24 }}
          />
          <Typography variant={"subtitle2"}>
            {params.row.name ?? params.row.phone}
          </Typography>
        </Stack>
      ),
  },
  {
    field: "state",
    headerName: "State",
    ...columnTypes.flexDefault,
  },
  {
    field: "postal_code",
    headerName: "Postal code",
    ...columnTypes.flexDefault,
    valueGetter: (params) => formatPostalCode(params.value),
  },
  {
    field: "billing",
    headerName: "Billing",
    ...columnTypes.flexDefault,
    valueGetter: (params) => {
      if ((params.row.subscription?.transaction_id?.length ?? 0) > 0) {
        return "Apple";
      }
      if ((params.row.subscription?.purchase_token?.length ?? 0) > 0) {
        return "Google";
      }
    },
    renderCell: (params) => (
      <Chip
        label={params.value}
        variant={"outlined"}
        color={"default"}
        size={"small"}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    ...columnTypes.flexDefault,
    valueGetter: (params) => params.row.subscription,
    renderCell: (params) => {
      return <StatusChip subscription={params.value} />;
    },
    sortComparator: (v1, v2) => {
      if (!v1.expired && v2.expired) {
        return -1;
      }
      if (v1.expired && !v2.expired) {
        return 1;
      }
      if (v1.auto_renew_enabled && !v2.auto_renew_enabled) {
        return -1;
      }
      if (!v1.auto_renew_enabled && v2.auto_renew_enabled) {
        return 1;
      }
      return 0;
    },
  },
  {
    field: "subscription_created_at",
    headerName: "Subscribed",
    ...columnTypes.timestamp,
    valueGetter: (params) =>
      (params.row.timestamps.subscription_created_at as Timestamp)?.toDate(),
  },
  {
    field: "created_at",
    headerName: "Joined",
    ...columnTypes.createdAt,
  },
];

const subscriptions = [
  where("subscription.environment", "==", "Production"),
  orderBy("timestamps.subscription_created_at", "desc"),
  limit(1000), // TODO: This query will need to be paginated when we have more subscribers.
];

function Subscriptions(): JSX.Element {
  const { database: db } = useFirebase();

  const navigate = useNavigate();
  const location = useLocation();

  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const [page, setPage] = useState(savedState.page || 0);
  const [items, loading] = useQuery<User>(
    query(collection(db, "users"), ...subscriptions)
  );
  const [filtered, setFiltered] = useState<User[]>(items);
  const [search, setSearch] = useState<string>(savedState.search || "");
  const [debounced] = useDebounce(search, 200);
  const handleRowClick = useNavigateOnRowClick();

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({ page: page, search: search });
  }, [page, search]);

  // Filter items on quick search input
  useEffect(() => {
    if (debounced) {
      const regex = new RegExp(debounced, "i");
      setFiltered(
        items.filter(
          (item) =>
            regex.test(item.name ?? "") ||
            regex.test(item.phone ?? "") ||
            regex.test(item.state ?? "") ||
            regex.test(item.postal_code ?? "")
        )
      );
    } else {
      setFiltered(items);
    }
  }, [items, debounced]);

  return (
    <React.Fragment>
      <PageHeader title="Subscriptions" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <QuickSearch value={search} onChange={setSearch} />

              <DataGridCard>
                <DataGrid
                  rows={filtered}
                  columns={columns}
                  {...standardProps}
                  sx={{
                    ".MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                  page={page}
                  onPageChange={setPage}
                  onRowClick={handleRowClick}
                />
              </DataGridCard>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}
export default Subscriptions;
