import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { User } from "../../types/user.db";

function PreferencesCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const updateMetricPreference = (newValue: boolean) => {
    updateDoc(user.docRef, {
      metric_units: newValue,
      "timestamps.updated_at": serverTimestamp(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to update metric preference", {
          variant: "error",
        });
      });
  };

  return (
    <PartitionedCard title={"Preferences"} spacing={4}>
      <PartitionedCardItem title={"Units"}>
        <ToggleButtonGroup
          color={"secondary"}
          size={"small"}
          exclusive
          value={user.metric_units === true ? "metric" : "imperial"}
          onChange={(event, value) => {
            if (value === "metric") {
              updateMetricPreference(true);
            }
            if (value === "imperial") {
              updateMetricPreference(false);
            }
          }}
        >
          <ToggleButton value="imperial" sx={{ paddingX: 6 }}>
            Imperial
          </ToggleButton>
          <ToggleButton value="metric" sx={{ paddingX: 6 }}>
            Metric
          </ToggleButton>
        </ToggleButtonGroup>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default PreferencesCard;
