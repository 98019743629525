import { Typography } from "@mui/material";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import useLoading from "../../hooks/useLoading";

function SubscriptionCountsCard(): JSX.Element {
  const [expired, setExpired] = useState<number | null>();
  const [expiring, setExpiring] = useState<number | null>();
  const [active, setActive] = useState<number | null>();
  const [vehicles, setVehicles] = useState<number | null>();
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "users");
    const q = query(
      collectionRef,
      where("subscription.environment", "==", "Production")
    );
    getDocs(q)
      .then((snapshot) => {
        let expired = 0;
        let expiring = 0;
        let active = 0;
        for (const doc of snapshot.docs) {
          const subscription = doc.data().subscription;
          if (subscription.expired === true) {
            expired++;
          } else if (subscription.auto_renew_enabled === false) {
            expiring++;
          } else {
            active++;
          }
        }
        setExpired(expired);
        setExpiring(expiring);
        setActive(active);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "smartcar-vehicles");
    getDocs(collectionRef)
      .then((snapshot) => {
        setVehicles(snapshot.size);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <PartitionedCard title={"Chargeway+"} spacing={2} loading={loading}>
      <PartitionedCardItem title={"Vehicles"} caption={"Connected"}>
        <Typography>{formatter.format(vehicles ?? 0)}</Typography>
      </PartitionedCardItem>
      <PartitionedCardItem title={"Subscriptions"} caption={"Active"}>
        <Typography>{formatter.format(active ?? 0)}</Typography>
      </PartitionedCardItem>
      <PartitionedCardItem title={"Subscriptions"} caption={"Not Renewing"}>
        <Typography>{formatter.format(expiring ?? 0)}</Typography>
      </PartitionedCardItem>
      <PartitionedCardItem title={"Subscriptions"} caption={"Expired"}>
        <Typography>{formatter.format(expired ?? 0)}</Typography>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default SubscriptionCountsCard;
