import { LaunchRounded } from "@mui/icons-material";
import { Button, Link, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";

import EditLocationDialog from "./EditLocationDialog";

function LocationCard({ beacon }: { beacon: WithDocRef<Beacon> }): JSX.Element {
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${beacon.location?.latitude}%2C${beacon.location?.longitude}`;

  return (
    <>
      <PartitionedCard
        title={"Location"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={4}
      >
        <PartitionedCardItem title={"Coordinates"}>
          {beacon.location ? (
            <Link href={mapsUrl} target={"_blank"}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Typography>
                  {[beacon.location.latitude, beacon.location.longitude].join(
                    ","
                  )}
                </Typography>
                <LaunchRounded fontSize={"inherit"} />
              </Stack>
            </Link>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>
        <PartitionedCardItem title={"Postal Code"}>
          <Typography>{beacon.postal_code ?? "None"}</Typography>
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditLocationDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          beacon={beacon}
        />
      )}
    </>
  );
}

export default LocationCard;
