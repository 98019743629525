import { Badge, Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import type { Beacon } from "../../types/beacon.db";
import { appVersionRegex } from "../../util/stdlib";

function VersionCounts({ beacons }: { beacons: Beacon[] }) {
  const [versions, setVersions] = useState<string[]>();

  // Update versions
  useEffect(() => {
    const versions = beacons
      .map((v) => v.last_user_agent?.match(appVersionRegex)?.[0])
      .filter((v) => !!v) as string[];
    const unique = [...new Set(versions)];
    unique
      .sort((a, b) => a.localeCompare(b, "en", { ignorePunctuation: true }))
      .reverse();
    setVersions(unique);
  }, [beacons]);

  const countForVersion = (appVersion: string) =>
    beacons.filter((v) => v.last_user_agent?.includes(appVersion)).length;

  return (
    <Stack direction={"row"} flexWrap={"wrap"} gap={4} sx={{ marginBottom: 4 }}>
      {versions &&
        versions.map((appVersion) => (
          <Badge
            key={appVersion}
            badgeContent={countForVersion(appVersion)}
            color={"secondary"}
          >
            <Chip variant={"outlined"} label={appVersion} />
          </Badge>
        ))}
    </Stack>
  );
}

export default VersionCounts;
