import {
  Edit as EditIcon,
  ErrorOutline,
  LaunchRounded,
  MoreVert as MoreIcon,
  OpenInBrowser,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Loader from "../components/Loader";
import { ManageImageCard } from "../components/ManageImageCard";
import { PageHeader } from "../components/PageHeader";
import useDoc from "../hooks/useDoc";
import useFirebase from "../hooks/useFirebase";
import type { WithDocRef } from "../hooks/useQuery";
import type { Vehicle } from "../types/vehicle.db";
import {
  FirestoreMenuItem,
  getPathFromUrl,
  getPublicUrl,
  isStorageUrl,
  updatedAt,
} from "../util/firebase";

import Page404 from "./auth/Page404";
import BatteryCard from "./vehicles/BatteryCard";
import CapabilitiesCard from "./vehicles/CapabilitiesCard";
import ChargingCard from "./vehicles/ChargingCard";
import EditFuelIdDialog from "./vehicles/EditFuelIdDialog";
import IncentivesList from "./vehicles/IncentivesList";
import RelatedVehiclesList from "./vehicles/RelatedVehiclesList";
import VinCard from "./vehicles/VinCard";

function VehicleMenuButton({
  vehicle,
}: {
  vehicle: WithDocRef<Vehicle>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-vehicle" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-vehicle"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenEditDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit FUEL ID</ListItemText>
          {vehicle.fuel_vehicle_id && (
            <Typography variant="body2" color="text.secondary">
              {vehicle.fuel_vehicle_id}
            </Typography>
          )}
        </MenuItem>

        <Divider />
        <FirestoreMenuItem docRef={vehicle.docRef} />
      </Menu>
      <EditFuelIdDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        fullScreen={fullScreen}
        vehicle={vehicle}
      />
    </React.Fragment>
  );
}

function PhotoMenuButton({ vehicle }: { vehicle: Vehicle }): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  //https://stock.fuelapi.com/vehicles/detail/1592

  const fuelId = vehicle.fuel_vehicle_id;

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "image-menu" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="image-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <MenuItem
          component={Link}
          href={`https://stock.fuelapi.com/vehicles/detail/${fuelId}`}
          target={"_blank"}
          disabled={!fuelId}
        >
          <ListItemIcon>
            <OpenInBrowser fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"View on FUEL"} />
          <Typography variant="body2" color="text.secondary">
            {fuelId}
          </Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function VehicleProfile(): JSX.Element {
  const { vehicleId } = useParams();
  const [vehicle, loading] = useDoc<Vehicle>("vehicles", vehicleId!);
  const [uploading, setUploading] = useState(false);
  const [openEditFuelIdDialog, setOpenEditFuelIdDialog] = useState(false);

  const { storage } = useFirebase();
  const { pathname } = useLocation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  const uploadPhoto = useCallback(
    async (files) => {
      try {
        setUploading(true);

        const photoUrl = vehicle?.photo_url;
        if (photoUrl && isStorageUrl(photoUrl)) {
          const storageRef = ref(storage.public, getPathFromUrl(photoUrl));
          await deleteObject(storageRef);
        }

        const first = files[0];
        const storageRef = ref(
          storage.public,
          ["img", "vehicles", vehicle!.id, first.name].join("/")
        );

        const snapshot = await uploadBytes(storageRef, first);
        await updateDoc(vehicle!.docRef, {
          photo_url: getPublicUrl(snapshot),
          ...updatedAt,
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to upload image.", { variant: "error" });
      } finally {
        setUploading(false);
      }
    },
    [storage, vehicle, enqueueSnackbar]
  );

  const removePhoto = useCallback(async () => {
    try {
      setUploading(true);

      const photoUrl = vehicle?.photo_url;
      if (photoUrl && isStorageUrl(photoUrl)) {
        const storageRef = ref(storage.public, getPathFromUrl(photoUrl));
        await deleteObject(storageRef);
      }

      await updateDoc(vehicle!.docRef, {
        photo_url: null,
        ...updatedAt,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to remove image.", { variant: "error" });
    } finally {
      setUploading(false);
    }
  }, [storage, vehicle, enqueueSnackbar]);

  const fuelId = vehicle?.fuel_vehicle_id ?? null;

  return (
    <React.Fragment>
      {loading && !vehicle ? (
        <Loader />
      ) : vehicle ? (
        <>
          <PageHeader
            overline={vehicle.year}
            title={vehicle.display_name}
            subtitle={vehicle.fuel_type}
            action={<VehicleMenuButton vehicle={vehicle} />}
            sectionIsId={true}
          />

          <Grid container spacing={fullScreen ? 4 : 6}>
            {!fuelId && (
              <Grid item xs={12}>
                <Alert
                  icon={<ErrorOutline fontSize="inherit" />}
                  severity="warning"
                  action={
                    <Button
                      color={"inherit"}
                      onClick={() => setOpenEditFuelIdDialog(true)}
                    >
                      EDIT
                    </Button>
                  }
                >
                  Vehicle has no Fuel ID.
                </Alert>
                <EditFuelIdDialog
                  open={openEditFuelIdDialog}
                  handleClose={() => setOpenEditFuelIdDialog(false)}
                  fullScreen={fullScreen}
                  vehicle={vehicle}
                />
              </Grid>
            )}

            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <ManageImageCard
                  title={"Photo"}
                  imageUrl={vehicle.photo_url}
                  onDrop={uploadPhoto}
                  onRemove={removePhoto}
                  loading={uploading}
                  height={250}
                  action={
                    <Stack direction={"row"}>
                      {vehicle.photo_url && (
                        <Button
                          size={"small"}
                          startIcon={<LaunchRounded />}
                          href={vehicle.photo_url}
                          target={"_blank"}
                        >
                          View Full
                        </Button>
                      )}
                      <PhotoMenuButton vehicle={vehicle} />
                    </Stack>
                  }
                />

                <ChargingCard vehicle={vehicle} />
                <BatteryCard vehicle={vehicle} />
                <VinCard vehicle={vehicle} />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} xl={8}>
              <CapabilitiesCard vehicle={vehicle} />
            </Grid>

            <Grid item xs={12}>
              <IncentivesList vehicle={vehicle} />
            </Grid>

            <Grid item xs={12}>
              <RelatedVehiclesList vehicle={vehicle} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default VehicleProfile;
