import { Stack } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";

const StyledPip = styled("div")({
  borderRadius: "1em",
  flexShrink: 0,
  flexGrow: 0,
});

function Pip({
  color,
  size = "medium",
  sx = [],
}: {
  color: "green" | "blue" | "red";
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}): JSX.Element {
  const theme = useTheme();
  return (
    <StyledPip
      sx={[
        {
          width: theme.spacing(size === "small" ? 2 : 3),
          height: theme.spacing(size === "small" ? 2 : 3),
          backgroundColor: theme.palette[color].main,
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}

function ConnectorPip({
  connectorType,
  size = "medium",
  sx = [],
}: {
  connectorType: string;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}): JSX.Element {
  const getColor = (type: string): "red" | "blue" | "green" => {
    switch (type.toUpperCase()) {
      case "NACS":
      case "TESLA":
        return "red";
      case "CHADEMO":
        return "blue";
      default:
        return "green";
    }
  };

  return <Pip color={getColor(connectorType)} size={size} sx={sx} />;
}

const renderPlugType = (params: any) => {
  return (params.value as string).split(",").map((value, i) => (
    <Stack direction="row" alignItems={"center"} key={i}>
      <ConnectorPip
        connectorType={params.value}
        size="small"
        sx={{
          marginRight: 1.5,
        }}
      />
      {params.value}
    </Stack>
  ));
};

export { Pip, ConnectorPip, renderPlugType };
