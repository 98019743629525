import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import {
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import type { PhoneNumber } from "libphonenumber-js";
import {
  AsYouType,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import useFirebase from "../../hooks/useFirebase";
import type { Network } from "../../types/network.db";

function EditSupportDialog({
  open,
  handleClose,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  network: Network;
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Reset the form
  useEffect(() => {
    if (open) {
      let phoneNumber: PhoneNumber | null = null;
      try {
        if (network.support_phone) {
          phoneNumber = parsePhoneNumber(network.support_phone, "US");
        }
      } catch (e) {
        console.warn(e);
      }
      reset({
        support_phone:
          phoneNumber?.formatNational() ?? network.support_phone ?? "",
        support_email: network.support_email ?? "",
        support_website: network.support_website ?? "",
      });
    }
  }, [open, reset, network]);

  const onSubmit = async (data: any) => {
    const collectionRef = collection(database, "networks");
    const docRef = doc(collectionRef, network.id);

    let phoneNumber: PhoneNumber | null = null;

    try {
      if (data.support_phone) {
        phoneNumber = parsePhoneNumber(data.support_phone, "US");
      }
    } catch (e) {
      console.warn(e);
    }

    try {
      await updateDoc(docRef, {
        support_phone: phoneNumber?.format("E.164") || null,
        support_email: data.support_email || null,
        support_website: data.support_website || null,
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar("Updated network");
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update network", { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Support"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"support_phone"}
          control={control}
          rules={{
            validate: (v) =>
              v === "" ||
              isPossiblePhoneNumber(v, "US") ||
              "Not a valid Phone Number",
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Support Phone"
              helperText={error?.message}
              error={Boolean(error)}
              onChange={(event) => {
                const value = new AsYouType("US").input(event.target.value);
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
        <Controller
          name={"support_email"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Support Email"
              helperText={error?.message}
              fullWidth
            />
          )}
        />
        <Controller
          name={"support_website"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Support Website"
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

function NetworkSupportCard({ network }: { network: Network }): JSX.Element {
  const [open, setOpen] = useState(false);
  return (
    <>
      <PartitionedCard
        title={"Support"}
        action={
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={4}
      >
        <PartitionedCardItem title={"Phone"}>
          <Typography>
            {network.support_phone
              ? parsePhoneNumber(network.support_phone).formatNational()
              : "None"}
          </Typography>
        </PartitionedCardItem>
        <PartitionedCardItem title={"Email"}>
          {network.support_email ? (
            <Link href={`mailto:${network.support_email}`} target={"_blank"}>
              {network.support_email}
            </Link>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>
        <PartitionedCardItem title={"Website"}>
          {network.support_website ? (
            <Link href={network.support_website} target={"_blank"}>
              {network.support_website}
            </Link>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>
      </PartitionedCard>
      {open && (
        <EditSupportDialog
          open={true}
          handleClose={() => setOpen(false)}
          network={network}
        />
      )}
    </>
  );
}

export default NetworkSupportCard;
