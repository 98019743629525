import { startOfMonth, subMinutes, subMonths } from "date-fns";
import {
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { StatCard, StatCardItem } from "../../components/StatCard";
import useLoading from "../../hooks/useLoading";

function UserCountsCard(): JSX.Element {
  const [totalUsers, setTotalUsers] = useState<number | null>();
  const [lastMonth, setLastMonth] = useState<number | null>();
  const [thisMonth, setThisMonth] = useState<number | null>();
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "users");
    getCountFromServer(query(collectionRef, where("is_anonymous", "==", false)))
      .then((snapshot) => {
        setTotalUsers(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "users");

    const now = new Date();
    const startAt = startOfMonth(subMonths(now, 1));
    const endAt = startOfMonth(now);

    const utcStartAt = subMinutes(startAt, startAt.getTimezoneOffset());
    const utcEndAt = subMinutes(endAt, endAt.getTimezoneOffset());

    getCountFromServer(
      query(
        collectionRef,
        where("timestamps.created_at", ">=", utcStartAt),
        where("timestamps.created_at", "<", utcEndAt),
        where("is_anonymous", "==", false)
      )
    )
      .then((snapshot) => {
        setLastMonth(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "users");

    const startAt = startOfMonth(new Date());
    const utcStartAt = subMinutes(startAt, startAt.getTimezoneOffset());

    getCountFromServer(
      query(
        collectionRef,
        where("timestamps.created_at", ">=", utcStartAt),
        where("is_anonymous", "==", false)
      )
    )
      .then((snapshot) => {
        setThisMonth(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <StatCard title={"Users"} loading={loading}>
      <StatCardItem
        value={totalUsers ? formatter.format(totalUsers) : ""}
        caption={"Total"}
      />
      <StatCardItem
        value={lastMonth ? formatter.format(lastMonth) : ""}
        caption={"New last month"}
      />
      <StatCardItem
        value={thisMonth ? formatter.format(thisMonth) : ""}
        caption={"New this month"}
      />
    </StatCard>
  );
}

export default UserCountsCard;
