import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page404() {
  return (
    <Wrapper>
      <Helmet title="Not Found" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Not Found
      </Typography>
      <Typography component="h2" variant="subtitle1" align="center">
        The content you are looking for might have moved.
      </Typography>
    </Wrapper>
  );
}

export default Page404;
