import { Launch as LaunchIcon } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import type { DocumentReference } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import type { UploadResult } from "firebase/storage";
import React from "react";

/**
 * If the given value has a `toDate` function then returns the
 * result of `value.toDate().toISOString()`; otherwise returns the value.
 */
function timestampToISOString(value: any) {
  return typeof value.toDate === "function"
    ? value.toDate().toISOString()
    : value;
}

/**
 * If the given value is an object with a `timestamps` child then
 * convert each entry to the ISOString representation.
 *
 * Safe to call on non-compliant objects.
 */
function convertTimestamps(o: any) {
  const copy = { ...o };
  if (typeof copy.timestamps === "object") {
    copy.timestamps = Object.fromEntries(
      Object.entries(copy.timestamps).map(([key, value]) => [
        key,
        timestampToISOString(value),
      ])
    );
  }
  return copy;
}

const updatedAt = {
  "timestamps.updated_at": serverTimestamp(),
};

/** Returns the public download URL from the upload result. */
const getPublicUrl = (snapshot: UploadResult) => {
  return [
    "https://storage.googleapis.com",
    snapshot.metadata.bucket,
    encodeURI(snapshot.metadata.fullPath),
  ].join("/");
};

/** Returns the public storage path from the image URL. */
const getPathFromUrl = (url: string) => {
  const regex = /^https:\/\/(.+?)\/(.+?)\//;
  return decodeURI(url.replace(regex, ""));
};

/** Returns true if the given url references a GCS object. */
const isStorageUrl = (url?: string | null) =>
  url && url.startsWith("https://storage.googleapis.com");

/** Returns a link to view this document in the Firebase console. */
const firestoreLink = (docRef: DocumentReference) => {
  return [
    "https://console.firebase.google.com/u/0/project",
    process.env.REACT_APP_FIREBASE_PROJECT_ID,
    "firestore/databases/-default-/data",
    docRef.path.replace("/", "~2F"),
  ].join("/");
};

/** Link to view this document in the Firebase console. */
function FirestoreMenuItem({
  docRef,
}: {
  docRef: DocumentReference;
}): JSX.Element {
  return (
    <MenuItem component={"a"} href={firestoreLink(docRef)} target={"_blank"}>
      <ListItemIcon>
        <LaunchIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText secondary={process.env.REACT_APP_FIREBASE_PROJECT_ID}>
        View in Firebase
      </ListItemText>
    </MenuItem>
  );
}

export {
  timestampToISOString,
  convertTimestamps,
  updatedAt,
  getPublicUrl,
  getPathFromUrl,
  isStorageUrl,
  firestoreLink,
  FirestoreMenuItem,
};
