import { Stack, TextField } from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";

/**
 * Returns a Lat/Lng tuple from a string. Values may be NaN if parsing failed.
 */
const parseLatLng = (value: string): [number, number] => {
  const [lat, lng] = value.split(",");
  return [Number.parseFloat(lat), Number.parseFloat(lng)];
};

function EditLocationDialog({
  open,
  handleClose,
  beacon,
}: {
  open: boolean;
  handleClose: () => void;
  beacon: WithDocRef<Beacon>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        beacon_coordinates: beacon.location
          ? [beacon.location.latitude, beacon.location.longitude].join(",")
          : "",
        beacon_postal_code: beacon.postal_code ?? "",
      });
    }
  }, [open, reset, beacon]);

  const onSubmit = async (data: any) => {
    try {
      const [lat, lng] = parseLatLng(data.beacon_coordinates);
      const location = !(Number.isNaN(lat) || Number.isNaN(lng))
        ? { latitude: lat, longitude: lng }
        : null;

      await updateDoc(beacon.docRef, {
        location,
        postal_code: data.beacon_postal_code,
        "timestamps.updated_at": serverTimestamp(),
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update location", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  const validateCoordinates = (value: string): boolean | string => {
    if (value) {
      const [lat, lng] = parseLatLng(value);
      if (Number.isNaN(lat) || Number.isNaN(lng)) {
        return "Please submit a coordinate in the format 45.523064,-122.676483";
      }
    }
    return true;
  };

  const validatePostalCode = (value: string): boolean | string => {
    if (value) {
      const isValid = new RegExp(/^[\d]{5}$/).test(value);
      if (!isValid) {
        return "Please enter a five-digit zipcode";
      }
    }
    return true;
  };

  return (
    <FormDialog
      title={"Edit Location"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"beacon_coordinates"}
          control={control}
          rules={{ validate: validateCoordinates }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Coordinates"
              helperText={
                error?.message ??
                "A latitude and longitude pair separated by a comma"
              }
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Controller
          name={"beacon_postal_code"}
          control={control}
          rules={{ validate: validatePostalCode }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Postal Code"
              placeholder={"97227"}
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditLocationDialog;
