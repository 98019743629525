type VehicleStub = {
  year: number;
  make: string;
  model: string;
  trim?: string;
};

const compareVehicles = (a: VehicleStub, b: VehicleStub) =>
  a.make.localeCompare(b.make) ||
  a.model.localeCompare(b.model) ||
  (a.trim ? a.trim.localeCompare(b.trim ?? "") : 0) ||
  (b.trim ? -b.trim.localeCompare(a.trim ?? "") : 0) ||
  b.year - a.year;

export { compareVehicles };
