import { Launch as LaunchIcon } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import type {
  GridColumns,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { orderBy } from "firebase/firestore";
import React, { useCallback } from "react";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useCollection from "../hooks/useCollection";
import type { Dealer } from "../types/dealer.db";

const columns: GridColumns<Dealer> = [
  {
    field: "name",
    headerName: "Name",
    ...columnTypes.flexDefault,
  },
  {
    field: "address",
    headerName: "Address",
    ...columnTypes.flexDefault,
    valueGetter: (params) => {
      return [params.row.address.street1, params.row.address.street2]
        .filter((v) => v !== null)
        .join(" ");
    },
  },
  {
    field: "city",
    headerName: "City",
    ...columnTypes.flexDefault,
    valueGetter: (params) => {
      return params.row.address.city;
    },
  },
  {
    field: "state",
    headerName: "State",
    ...columnTypes.flexDefault,
    valueGetter: (params) => {
      return params.row.address.state;
    },
  },
  {
    field: "vendor",
    headerName: "Website Vendor",
    ...columnTypes.flexDefault,
    valueGetter: (params) => {
      return params.row.website.vendor;
    },
  },
  {
    field: "actions",
    headerName: "",
    ...columnTypes.flexDefault,
    renderCell: (params) => (
      <Button
        size={"small"}
        endIcon={<LaunchIcon />}
        onClick={() => {
          window.open(params.row.website.url, "_blank");
        }}
      >
        Website
      </Button>
    ),
  },
];

function Dealers(): JSX.Element {
  const [items, loading] = useCollection<Dealer>("dealers", orderBy("name"));

  const handleRowClick = useCallback<GridEventListener<"rowClick">>(
    (params: GridRowParams<Dealer>) => {
      console.debug({ id: params.id });
    },
    []
  );

  return (
    <React.Fragment>
      <PageHeader title={"Dealers"} />

      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <DataGridCard>
              <DataGrid
                rows={items}
                columns={columns}
                {...standardProps}
                sx={{
                  ".MuiDataGrid-row:hover": {
                    cursor: "pointer",
                  },
                }}
                onRowClick={handleRowClick}
              />
            </DataGridCard>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default Dealers;
