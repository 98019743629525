import { darken } from "@mui/material";
import { grey } from "@mui/material/colors";
import merge from "deepmerge";

import { THEMES } from "../constants";

export const cw = {
  black: "#000000",
  white: "#FFFFFF",
  brandGreen: "#85F70B",
  gray: {
    900: "#0D0D0F",
    800: "#141417",
    700: "#1C1C21",
    600: "#232329",
    500: "#292930",
    400: "#34343D",
    300: "#4F4F5B",
    200: "#666675",
    100: "#868695",
    75: "#AAAAB9",
    50: "#CFCFDA",
    25: "#F4F4F5",
  },
  green: {
    800: "#00540E",
    700: "#007A14",
    600: "#029F1C",
    500: "#00C120",
    300: "#3AEC58",
  },
  orange: {
    500: "#FE9800",
  },
  red: {
    900: "#5C0500",
    700: "#850700",
    500: "#D40C00",
    400: "#FF645B",
    300: "#E99F9B",
  },
  blue: {
    900: "#002A7C",
    700: "#0037A6",
    500: "#004CD1",
    400: "#4D86F6",
    300: "#9BB5E9",
  },
};

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2",
};

const connectors = {
  green: "#00C120",
  blue: "#004CD1",
  red: "#D40C00",
};

const lightVariant = {
  name: THEMES.DAY,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    info: {
      main: cw.gray["100"],
    },
    green: {
      main: darken(connectors.green, 0),
      contrastText: "#FFF",
    },
    blue: {
      main: darken(connectors.blue, 0),
      contrastText: "#FFF",
    },
    red: {
      main: darken(connectors.red, 0),
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#F7F9FC",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: cw.gray["25"],
    background: cw.gray["800"],
    header: {
      color: cw.gray["25"],
      background: cw.gray["800"],
    },
    footer: {
      color: cw.gray["25"],
      background: cw.gray["800"],
    },
    badge: {
      color: cw.white,
      background: customBlue[500],
    },
  },
};

const nightVariant = merge(lightVariant, {
  name: THEMES.NIGHT,
  palette: {
    mode: "dark",
    primary: {
      main: cw.blue["400"],
      contrastText: cw.white,
    },
    error: {
      main: cw.red["500"],
    },
    warning: {
      main: cw.orange["500"],
    },
    info: {
      main: cw.gray["100"],
    },
    success: {
      main: cw.green["300"],
    },
    background: {
      default: cw.gray["900"],
      paper: cw.gray["800"],
    },
    text: {
      primary: cw.gray["25"],
      secondary: cw.gray["100"],
    },
    green: {
      main: darken(connectors.green, 0.1),
      contrastText: cw.white,
    },
    blue: {
      main: darken(connectors.blue, 0.1),
      contrastText: cw.white,
    },
    red: {
      main: darken(connectors.red, 0.1),
      contrastText: cw.white,
    },
  },
  header: {
    color: cw.gray["25"],
    background: cw.gray["900"],
    search: {
      color: cw.gray["25"],
    },
  },
  footer: {
    color: cw.white,
    background: cw.gray["900"],
  },
  sidebar: {
    color: cw.gray["25"],
    background: cw.gray["800"],
    header: {
      color: cw.gray["25"],
      background: cw.gray["800"],
    },
    footer: {
      color: cw.gray["25"],
      background: cw.gray["800"],
    },
    badge: {
      color: cw.white,
      background: customBlue[500],
    },
  },
});

const variants: Array<VariantType> = [lightVariant, nightVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
    green: MainContrastTextType;
    blue: MainContrastTextType;
    red: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType;
    footer: ColorBgType;
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
