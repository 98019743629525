import {
  Avatar,
  Grid,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { subDays } from "date-fns";
import {
  collection as firestoreCollection,
  doc,
  getDoc,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useFirebase from "../hooks/useFirebase";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import useQuery from "../hooks/useQuery";
import type { SavedState } from "../types/navigate";
import type { StationReview } from "../types/station-review.db";
import type { User } from "../types/user.db";

const recent = [
  where("timestamps.created_at", ">=", subDays(new Date(), 14)),
  orderBy("timestamps.created_at", "desc"),
];

const listReducer = (
  state: User[],
  action: { type: "add"; newItem: User }
): User[] => {
  switch (action.type) {
    case "add":
      return [...state, action.newItem];
  }
  throw Error("Unknown action: " + action.type);
};

function Reviews(): JSX.Element {
  const { database: db } = useFirebase();
  const collectionRef = firestoreCollection(db, "station-reviews");
  const [reviews, loading] = useQuery<StationReview>(
    query(collectionRef, ...recent)
  );
  const [users, dispatch] = useReducer(listReducer, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const [page, setPage] = useState(savedState.page || 0);
  const handleRowClick = useNavigateOnRowClick();

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({ page: page });
  }, [page]);

  // Load users
  useEffect(() => {
    const unique = [...new Set(reviews.map((review) => review.user_id))];
    for (const userId of unique) {
      getDoc(doc(db, "users", userId)).then((doc) => {
        if (doc.exists()) {
          const user = { id: doc.id, ...doc.data() } as User;
          dispatch({ type: "add", newItem: user });
        }
      });
    }
  }, [db, reviews]);

  const columns = useMemo<GridColumns<StationReview>>(
    () => [
      {
        field: "user",
        headerName: "User",
        ...columnTypes.flexDefault,
        minWidth: 200,
        valueGetter: (params) => users.find((v) => v.id === params.row.user_id),
        renderCell: (params) =>
          params.value && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Avatar
                src={params.value.photo_url ?? ""}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant={"subtitle2"}>
                {params.value.name ?? params.value.phone}
              </Typography>
            </Stack>
          ),
      },
      {
        field: "title",
        headerName: "Title",
        ...columnTypes.flexDefault,
        minWidth: 250,
      },
      {
        field: "comment",
        headerName: "Comment",
        ...columnTypes.flexPrimary,
        minWidth: 350,
        renderCell: (params) => (
          <Tooltip title={params.value} enterDelay={500}>
            <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {params.value}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "rating",
        headerName: "Rating",
        ...columnTypes.flexNumber,
        renderCell: (params) => (
          <Rating value={params.value} size="small" readOnly />
        ),
      },
      {
        field: "created_at",
        headerName: "First created",
        ...columnTypes.createdAt,
      },
    ],
    [users]
  );

  return (
    <React.Fragment>
      <PageHeader title="Reviews" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DataGridCard>
                <DataGrid
                  rows={reviews}
                  columns={columns}
                  {...standardProps}
                  sx={{
                    ".MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                  page={page}
                  onPageChange={setPage}
                  onRowClick={handleRowClick}
                />
              </DataGridCard>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}
export default Reviews;
