import {
  Delete as DeleteIcon,
  MoreVert as MoreIcon,
} from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import StationCard from "../components/StationCard";
import UserCard from "../components/UserCard";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { StationPhoto } from "../types/station-photo.db";
import { FirestoreMenuItem } from "../util/firebase";

import Page404 from "./auth/Page404";
import GeneralCard from "./station-photos/GeneralCard";
import PhotoCard from "./station-photos/PhotoCard";

function MoreOptionsMenu({
  photo,
}: {
  photo: WithDocRef<StationPhoto>;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-photo" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-photo"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>

        <Divider />
        <FirestoreMenuItem docRef={photo.docRef} />
      </Menu>
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={photo.docRef}
        displayName={"Station Photo"}
      />
    </React.Fragment>
  );
}

function PhotoProfile(): JSX.Element {
  const { docId } = useParams();
  const [photo, loading] = useDoc<StationPhoto>("station-photos", docId!);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : photo ? (
        <>
          <PageHeader
            title={"Station Photo"}
            action={<MoreOptionsMenu photo={photo} />}
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <PhotoCard
                  photo={photo}
                  href={photo.photo_url}
                  target={"_blank"}
                  options={{
                    showHeader: false,
                    fixedHeight: false,
                  }}
                />
                <GeneralCard photo={photo} />
                <StationCard guid={photo.khloud_station_guid} />
                <UserCard userId={photo.user_id} />
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default PhotoProfile;
