import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Promotion } from "../../types/promotion.db";
import type { VehicleMake } from "../../types/vehicle.db";
import { range } from "../../util/stdlib";

function AddVehicleDialog({
  open,
  handleClose,
  promotion,
}: {
  open: boolean;
  handleClose: () => void;
  promotion: WithDocRef<Promotion>;
}): JSX.Element {
  const { database } = useFirebase();

  const [makes, setMakes] = useState<VehicleMake[]>([]);
  const [makeOptions, setMakeOptions] = useState<string[] | null>();
  const [modelOptions, setModelOptions] = useState<string[] | null>();

  const [year, setYear] = useState<string | null>();
  const [make, setMake] = useState<string | null>();
  const [model, setModel] = useState<string | null>();

  const [results, setResults] = useState<any[] | null>();
  const [selected, setSelected] = useState<any[]>([]);
  const [addMore, setAddMore] = useState(false);

  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Reset the form when opened
  useEffect(() => {
    if (open) {
      setYear(null);
      setMake(null);
      setModel(null);
      setModelOptions(null);
      setResults(null);
      setSelected([]);
      setAddMore(false);
    }
  }, [open]);

  // Load makes and models
  useEffect(() => {
    const q = query(collection(database, "vehicle-makes"), orderBy("name"));

    getDocs(q).then((snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) as VehicleMake[];
      setMakes(data);
      setMakeOptions(data.map((v) => v.name));
      setLoading(false);
    });
  }, [database]);

  // Filter makes
  useEffect(() => {
    if (!year || year.length !== 4) return;

    setMakeOptions(
      makes
        .filter((v) => v.model_years.includes(parseInt(year)))
        .map((v) => v.name)
    );
  }, [makes, year]);

  // Filter models
  useEffect(() => {
    if (!year || year.length !== 4) return;

    if (make) {
      setModelOptions(makes.find((v) => v.name === make)?.models);
    } else {
      setModelOptions(null);
    }
  }, [makes, year, make]);

  // Load results
  useEffect(() => {
    if (!year || !make || !model || year.length !== 4) return;

    const q = query(
      collection(database, "vehicles"),
      where("year", "==", parseInt(year)),
      where("make", "==", make),
      where("model", "==", model)
    );

    getDocs(q).then((snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      }) as any[];

      results.sort((a, b) =>
        a.trim && b.trim ? a.trim.localeCompare(b.trim) : 0
      );
      setResults(results);
      setSelected(results);
    });
  }, [database, year, make, model]);

  const onCancel = () => handleClose();
  const onSubmit = async () => {
    const update: any = {};

    for (const s of selected) {
      update[`criteria.vehicles.${s.id}`] = {
        id: s.id,
        year: s.year,
        make: s.make,
        model: s.model,
        trim: s.trim,
        fuel_type: s.fuel_type,
        display_name: s.display_name,
      };
    }

    await updateDoc(promotion.docRef, {
      ...update,
      "timestamps.updated_at": serverTimestamp(),
    });
    if (!addMore) handleClose();
  };

  const handleToggle = (stub: any) => {
    const isSelected = !!selected.find((value) => value.id === stub.id);
    if (isSelected) {
      setSelected(selected.filter((value) => value.id !== stub.id));
    } else {
      setSelected([...selected, stub]);
    }
  };

  const nextYear = new Date().getFullYear() + 1;
  const years = range(2011, nextYear)
    .reverse()
    .map((n) => n.toString());

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        handleClose();
      }}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <Box>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>Add a vehicle</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={4} mt={6}>
            <Autocomplete
              autoHighlight
              autoSelect
              freeSolo
              options={years}
              onChange={(event, newValue) => setYear(newValue)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Year" autoFocus />
              )}
            />
            <Autocomplete
              autoHighlight
              autoSelect
              options={makeOptions ?? []}
              onChange={(event, newValue) => setMake(newValue)}
              fullWidth
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Make"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Autocomplete
              autoHighlight
              autoSelect
              options={modelOptions ?? []}
              onChange={(event, newValue) => setModel(newValue)}
              fullWidth
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Model"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Stack>

          <Collapse in={!!results}>
            {results && results.length > 0 ? (
              <List sx={{ marginTop: 2 }}>
                {results.map((v) => {
                  const isAdded = !!promotion.criteria?.vehicles?.[v.id];
                  return (
                    <ListItem
                      key={v.id}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggle(v)}
                          checked={
                            !!selected.find((value) => value.id === v.id)
                          }
                          disabled={isAdded}
                          tabIndex={-1}
                          disableRipple
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleToggle(v)}
                        disabled={isAdded}
                      >
                        <ListItemText
                          primary={`${v.year} ${v.make} ${v.model}`}
                          secondary={v.trim}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography variant="body1" textAlign="center" mt={6}>
                No Results
              </Typography>
            )}
          </Collapse>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            label="Add more"
            labelPlacement="end"
            checked={addMore}
            onChange={(event, checked) => setAddMore(checked)}
            control={<Switch size="small" />}
            componentsProps={{
              typography: {
                color: "text.secondary",
              },
            }}
          />
          <Button onClick={onCancel}>{addMore ? "Done" : "Cancel"}</Button>
          <Button onClick={onSubmit}>{addMore ? "Add" : "Save"}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default AddVehicleDialog;
