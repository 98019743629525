import type { SelectChangeEvent } from "@mui/material";
import { FormControlLabel, MenuItem, Select, Switch } from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon, BeaconSettings } from "../../types/beacon.db";

function SettingsCard({ beacon }: { beacon: WithDocRef<Beacon> }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [settings, setSettings] = useState<BeaconSettings>(beacon.settings);

  const updateSettings = (newValue: BeaconSettings) => {
    newValue.show_vehicle_brand_filters = newValue.show_add_new_vehicle
      ? newValue.show_vehicle_brand_filters
      : false;

    setSettings(newValue);
    updateDoc(beacon.docRef, {
      settings: newValue,
      "timestamps.updated_at": serverTimestamp(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to update settings", {
          variant: "error",
        });
      });
  };

  const updateTimeout = (newValue: number) => {
    updateSettings({ ...settings, attract_loop_timeout: newValue });
  };

  return (
    <>
      <PartitionedCard title={"Settings"} spacing={4}>
        <PartitionedCardItem title={"Beacon Analytics"} alignItems={"center"}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.analytics_enabled}
                onChange={(event) => {
                  updateSettings({
                    ...settings,
                    analytics_enabled: event.target.checked,
                  });
                }}
              />
            }
            label={settings.analytics_enabled ? "Enabled" : "Disabled"}
          />
        </PartitionedCardItem>

        <PartitionedCardItem title={"Attract Timeout"} alignItems={"center"}>
          <Select
            size={"small"}
            variant={"outlined"}
            sx={{ width: 200 }}
            value={settings.attract_loop_timeout ?? 600}
            onChange={(event: SelectChangeEvent<any>) => {
              updateTimeout(parseInt(event.target.value));
            }}
          >
            <MenuItem value={600}>10 minutes</MenuItem>
            <MenuItem value={300}>5 minutes</MenuItem>
            <MenuItem value={120}>2 minutes</MenuItem>
            <MenuItem value={60}>1 minute</MenuItem>
            <MenuItem value={30}>30 seconds</MenuItem>
          </Select>
        </PartitionedCardItem>

        <PartitionedCardItem title={"New Vehicle Flow"} alignItems={"center"}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.show_add_new_vehicle}
                onChange={(event) => {
                  updateSettings({
                    ...settings,
                    show_add_new_vehicle: event.target.checked,
                  });
                }}
              />
            }
            label={settings.show_add_new_vehicle ? "Enabled" : "Disabled"}
          />
        </PartitionedCardItem>

        <PartitionedCardItem title={"Used Vehicle Flow"} alignItems={"center"}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.show_add_used_vehicle}
                onChange={(event) => {
                  updateSettings({
                    ...settings,
                    show_add_used_vehicle: event.target.checked,
                  });
                }}
              />
            }
            label={settings.show_add_used_vehicle ? "Enabled" : "Disabled"}
          />
        </PartitionedCardItem>

        <PartitionedCardItem
          title={"Vehicle Brand Filters"}
          alignItems={"center"}
        >
          <FormControlLabel
            control={
              <Switch
                checked={settings.show_vehicle_brand_filters}
                onChange={(event) => {
                  updateSettings({
                    ...settings,
                    show_vehicle_brand_filters: event.target.checked,
                  });
                }}
                disabled={!settings.show_add_new_vehicle}
              />
            }
            label={settings.show_vehicle_brand_filters ? "Enabled" : "Disabled"}
          />
        </PartitionedCardItem>
      </PartitionedCard>
    </>
  );
}

export default SettingsCard;
