import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  deleteField,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import type { KeyboardEvent } from "react";
import { useEffect, useMemo, useState } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import useFirebase from "../../hooks/useFirebase";
import type { Incentive, IncentiveVehicle } from "../../types/incentive.db";
import { parseCurrency } from "../../util/stdlib";

import AddVehicleDialog from "./AddVehicleDialog";

function EditVehicleDialog({
  open,
  handleClose,
  incentive,
  selected,
}: {
  open: boolean;
  handleClose: (canceled?: boolean) => void;
  incentive: Incentive;
  selected: string[];
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setAmount] = useState<number | null>();
  const first = incentive.criteria?.vehicles?.[selected[0]];

  useEffect(() => {
    if (open) {
      setAmount(null);
    }
  }, [open]);

  const updateVehicle = async () => {
    const collectionRef = collection(database, "vehicle-incentives");
    const incentiveRef = doc(collectionRef, incentive.id);

    try {
      const docData: any = {};

      for (const id of selected) {
        docData[`criteria.vehicles.${id}.amount`] = amount;
      }

      await updateDoc(incentiveRef, {
        ...docData,
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar(
        selected.length > 1
          ? `Updated ${selected.length} vehicles`
          : `Updated "${first?.display_name}"`
      );
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(
        selected.length > 1
          ? `Updated ${selected.length} vehicles`
          : `Failed to update "${first?.display_name}"`,
        { variant: "error" }
      );
    } finally {
      handleClose();
    }
  };

  const handleKeyDown = async (event: KeyboardEvent) => {
    if (event.key === "Enter" && amount != null) {
      await updateVehicle();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(true)}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ padding: "24px 24px 0" }}>
        {selected.length > 1
          ? `Edit ${selected.length} vehicles`
          : `Edit "${first?.display_name}"`}
      </DialogTitle>
      <DialogContent>
        <Stack mt={6}>
          <TextField
            label="Amount"
            autoFocus={true}
            defaultValue={selected.length > 1 ? "" : first?.amount}
            onChange={(params) => setAmount(parseCurrency(params.target.value))}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)}>Cancel</Button>
        <Button onClick={updateVehicle} disabled={!amount}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RemoveVehicleDialog({
  open,
  handleClose,
  incentive,
  selected,
}: {
  open: boolean;
  handleClose: () => void;
  incentive: Incentive;
  selected: string[];
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const first = incentive.criteria?.vehicles?.[selected[0]];

  const deleteVehicle = async () => {
    const collectionRef = collection(database, "vehicle-incentives");
    const incentiveRef = doc(collectionRef, incentive.id);

    try {
      const docData: any = {};

      for (const id of selected) {
        docData[`criteria.vehicles.${id}`] = deleteField();
      }

      await updateDoc(incentiveRef, {
        ...docData,
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar(
        selected.length > 1
          ? `Removed ${selected.length} vehicles`
          : `Removed "${first?.display_name}"`
      );
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(
        selected.length > 1
          ? `Removed ${selected.length} vehicles`
          : `Failed to remove "${first?.display_name}"`
      );
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {selected.length > 1
            ? `Delete ${selected.length} vehicles?`
            : `Delete "${first?.display_name}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={deleteVehicle}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

function VehicleList({
  vehicles,
  incentive,
}: {
  vehicles: IncentiveVehicle[];
  incentive: Incentive;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const columns = useMemo<GridColumns<IncentiveVehicle>>(
    () => [
      {
        field: "year",
        headerName: "Year",
        ...columnTypes.flexDefault,
        minWidth: 100,
      },
      {
        field: "make",
        headerName: "Make",
        ...columnTypes.flexDefault,
      },
      {
        field: "model",
        headerName: "Model",
        ...columnTypes.flexDefault,
      },
      {
        field: "trim",
        headerName: "Trim",
        ...columnTypes.flexDefault,
        minWidth: 250,
      },
      {
        field: "fuel_type",
        headerName: "Type",
        ...columnTypes.flexDefault,
        minWidth: 100,
        renderCell: (params) => (
          <Chip label={params.value} variant="outlined" size="small" />
        ),
      },
      // {
      //   field: "msrp",
      //   headerName: "MSRP",
      //   ...columnTypes.currency,
      // },
      {
        field: "amount",
        headerName: "Amount",
        ...columnTypes.currency,
      },
      {
        field: "actions",
        headerName: "",
        ...columnTypes.flexActions,
        renderCell: (params) => (
          <Button
            size={"small"}
            endIcon={<LaunchIcon />}
            onClick={() => {
              window.open(`/vehicles/${params.id}`, "_blank");
            }}
          >
            View detail
          </Button>
        ),
      },
    ],
    []
  );

  const isEmpty = vehicles.length === 0;

  return (
    <Card>
      <CardHeader
        title="Vehicles"
        subheader={
          vehicles.length === 0
            ? "Applies to all vehicles until one is added."
            : ""
        }
        action={
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="text"
              onClick={() => setOpenAddDialog(true)}
            >
              Add
            </Button>
            <Button
              startIcon={<EditIcon />}
              variant="text"
              onClick={() => setOpenEditDialog(true)}
              disabled={selected.length === 0}
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="text"
              onClick={() => setOpenRemoveDialog(true)}
              disabled={selected.length === 0}
            >
              Remove
            </Button>
          </Stack>
        }
      />
      {!isEmpty && (
        <DataGrid
          rows={vehicles}
          columns={columns}
          {...standardProps}
          pageSize={20}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          checkboxSelection
          disableSelectionOnClick={false}
          onSelectionModelChange={(selectionModel, details) =>
            setSelected(selectionModel as string[])
          }
          selectionModel={selected}
        />
      )}

      <AddVehicleDialog
        open={openAddDialog}
        handleClose={() => setOpenAddDialog(false)}
        fullScreen={fullScreen}
        incentive={incentive}
      />
      <EditVehicleDialog
        open={openEditDialog}
        handleClose={(canceled) => {
          setOpenEditDialog(false);
          if (!canceled) setSelected([]);
        }}
        incentive={incentive}
        selected={selected}
      />
      <RemoveVehicleDialog
        open={openRemoveDialog}
        handleClose={() => setOpenRemoveDialog(false)}
        incentive={incentive}
        selected={selected}
      />
    </Card>
  );
}

export default VehicleList;
