import { styled } from "@mui/system";
import React from "react";

import { ReactComponent as ChargewayMark } from "../components/ChargewayMark.svg";

const Wrapper = styled("div")({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  minHeight: "100%",
});

const Mark = styled(ChargewayMark)(({ theme }) => ({
  width: "128px",
  opacity: theme.palette.mode === "light" ? 0.1 : 0.3,
}));

function Landing(): JSX.Element {
  return (
    <Wrapper>
      <Mark />
    </Wrapper>
  );
}

export default Landing;
