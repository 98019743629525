import type {
  AdapterSearchFilter,
  ConnectorType,
  PowerLevelSearchFilter,
} from "../types/vehicle.db.js";

/** Map connectors to a format appropriate for search filters. */
const levelsFromConnectors = (
  connectors: ConnectorType[]
): PowerLevelSearchFilter[] => {
  // Note: The default ranges must be zero for the following code to function properly.
  const levels: PowerLevelSearchFilter[] = ["BLUE", "GREEN", "RED"].map(
    (color) =>
      ({
        color,
        range_min: 0,
        range_max: 0,
        selected_min: 0,
        selected_max: 0,
        is_enabled: false,
      } as PowerLevelSearchFilter)
  );

  connectors
    .filter((c) => !(c.is_adapter || c.is_option))
    .forEach((c) => {
      const min = c.type === "AC" ? 1 : 3;
      const max = c.type === "AC" ? 2 : 7;

      // Note: This is pretty confusing so here's a little rundown of what this does.
      //
      // The output of this function is a list of objects that let the clients build
      // the power level search filter interface. This requires looping over a vehicle's
      // connectors to determine the range for a particular color.
      //
      // For example, a vehicle might have a GREEN AC connector that's levels 1-2 and a
      // GREEN DC connector that's levels 3-7.
      //
      // We need to merge those together into a single range of 1-7.

      const indexOf = levels.findIndex((v) => v.color === c.color);
      const oldMin = levels[indexOf]?.range_min ?? 0;
      const oldMax = levels[indexOf]?.range_max ?? 0;
      const newMin = min < oldMin || oldMin === 0 ? min : oldMin;
      const newMax = max > oldMax || oldMax === 0 ? max : oldMax;

      const update = {
        color: c.color,
        range_min: newMin,
        range_max: newMax,
        selected_min: newMin,
        selected_max: newMax,
        is_enabled: true,
      };
      if (indexOf !== -1) {
        levels[indexOf] = update;
      } else {
        levels.push(update);
      }
    });
  return levels;
};

/** Map adapters to a format appropriate for search filters. */
const adaptersFromConnectors = (
  connectors: ConnectorType[]
): AdapterSearchFilter[] => {
  return connectors
    .filter((c) => c.is_adapter)
    .map((c) => {
      const min = c.type === "AC" ? 1 : 3;
      const max = c.type === "AC" ? 2 : 7;
      return {
        type: c.type,
        name: c.name,
        color: c.color,
        range_min: min,
        range_max: max,
        is_enabled: false,
      };
    });
};

export { levelsFromConnectors, adaptersFromConnectors };
