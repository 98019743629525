import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import type { DocumentReference } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

function DeleteDialog({
  open,
  handleClose,
  docRef,
  displayName,
  navigateOnClose = true,
}: {
  open: boolean;
  handleClose: () => void;
  docRef: DocumentReference;
  displayName: string;
  navigateOnClose?: boolean;
}): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    try {
      await deleteDoc(docRef);
      if (navigateOnClose) navigate("..");
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(`Failed to delete ${displayName}.`, { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Delete "{displayName}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export { DeleteDialog };
