import {
  Delete as DeleteIcon,
  MoreVert as MoreIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Avatar,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { addHours, formatDistanceToNowStrict } from "date-fns";
import {
  collection,
  deleteField,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { parsePhoneNumber } from "libphonenumber-js";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ConfirmDialog } from "../components/dialogs/ConfirmDialog";
import Loader from "../components/Loader";
import NotesCard from "../components/NotesCard";
import { PageHeader } from "../components/PageHeader";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { User } from "../types/user.db";
import { FirestoreMenuItem } from "../util/firebase";

import Page404 from "./auth/Page404";
import GeneralCard from "./users/GeneralCard";
import PreferencesCard from "./users/PreferencesCard";
import QuestionnaireCard from "./users/QuestionnaireCard";
import SettingsCard from "./users/SettingsCard";
import SubscriptionCard from "./users/SubscriptionCard";
import VehiclesCard from "./users/VehiclesCard";

function MenuButton({ user }: { user: WithDocRef<User> }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  const handleDelete = () => {
    updateDoc(user.docRef, {
      delete_requested: true,
      "timestamps.updated_at": serverTimestamp(),
      "timestamps.delete_requested_at": Timestamp.now(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to request deletion", {
          variant: "error",
        });
      })
      .finally(() => {
        setOpenDeleteDialog(false);
      });
  };

  return (
    <React.Fragment>
      {fullScreen ? (
        <Button
          aria-owns={anchorEl ? "menu-user" : undefined}
          aria-haspopup="true"
          variant={"outlined"}
          color={"primary"}
          size={"large"}
          fullWidth
          onClick={toggleMenu}
        >
          Menu
        </Button>
      ) : (
        <IconButton
          aria-owns={anchorEl ? "menu-user" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
        >
          <MoreIcon />
        </IconButton>
      )}
      <Menu
        id="menu-user"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
          disabled={user.delete_requested}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>

        <Divider />

        <FirestoreMenuItem docRef={user.docRef} />
      </Menu>

      <ConfirmDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleSubmit={handleDelete}
        submitButtonText={"Yes, Delete"}
        dialogContent={
          "Schedule this account to be deleted? This request can be canceled within 24 hours."
        }
      />
    </React.Fragment>
  );
}

function AlertDeletePending({ user }: { user: WithDocRef<User> }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteAt, setDeleteAt] = useState<string>();
  const [processingCancelDelete, setProcessingCancelDelete] = useState(false);

  useEffect(() => {
    const deleteRequestedAt = (
      user?.timestamps.delete_requested_at as Timestamp
    )?.toDate();
    if (deleteRequestedAt) {
      const formatted = formatDistanceToNowStrict(
        addHours(deleteRequestedAt, 24),
        {}
      );
      setDeleteAt(formatted);
    }
  }, [user]);

  const handleCancelDelete = useCallback(() => {
    setProcessingCancelDelete(true);
    updateDoc(user.docRef, {
      delete_requested: deleteField(),
      "timestamps.updated_at": serverTimestamp(),
      "timestamps.delete_requested_at": deleteField(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to cancel deletion", {
          variant: "error",
        });
      })
      .finally(() => {
        setProcessingCancelDelete(false);
      });
  }, [user, enqueueSnackbar]);

  return (
    <Collapse in={user.delete_requested} appear={false}>
      <Alert
        severity="error"
        action={
          <LoadingButton
            color={"inherit"}
            onClick={() => handleCancelDelete()}
            loading={processingCancelDelete}
          >
            Cancel
          </LoadingButton>
        }
        sx={{ alignItems: "center", marginBottom: 6 }}
      >
        {deleteAt
          ? `This account is scheduled to be deleted in ${deleteAt}.`
          : "This account is scheduled to be deleted."}
      </Alert>
    </Collapse>
  );
}

function UserProfile(): JSX.Element {
  const { docId } = useParams();
  const [user, loading] = useDoc<User>("users", docId!);
  const [hasConnectedVehicle, setHasConnectedVehicle] = useState<
    boolean | null
  >(null);
  const phoneNumber = user?.phone ? parsePhoneNumber(user.phone, "US") : null;

  useEffect(() => {
    const db = getFirestore();
    const collectionRef = collection(db, "smartcar-vehicles");
    getDocs(query(collectionRef, where("user_id", "==", docId)))
      .then((snapshot) => {
        setHasConnectedVehicle(!snapshot.empty);
      })
      .catch((err: any) => {
        console.warn(err);
      });
  }, [docId]);

  // TODO: VehiclesCard, ReviewsCard, PhotosCard

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : user ? (
        <>
          <PageHeader
            avatar={
              <Avatar
                alt={user.name}
                src={user.photo_url ?? ""}
                sx={{ width: 64, height: 64 }}
              />
            }
            title={user.name ?? "User"}
            subtitle={
              user.email ? (
                <Link href={"mailto:" + user.email}>{user.email}</Link>
              ) : (
                phoneNumber?.formatNational()
              )
            }
            action={<MenuButton user={user} />}
            sectionIsId={true}
          />

          <AlertDeletePending user={user} />

          {user.subscription?.expired === false &&
            hasConnectedVehicle === false && (
              <Alert
                severity="warning"
                sx={{ alignItems: "center", marginBottom: 6 }}
              >
                This account has an active subscription but no connected
                vehicle.
              </Alert>
            )}

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <GeneralCard user={user} />
                <SubscriptionCard user={user} />
                <QuestionnaireCard user={user} />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} xl={8}>
              <Stack spacing={6}>
                <PreferencesCard user={user} />
                <SettingsCard user={user} />
                <NotesCard docRef={user.docRef} />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <VehiclesCard user={user} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export { UserProfile as default };
