import { Link, Typography } from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import type { WithDocRef } from "../hooks/useQuery";
import type { Station } from "../types/station.db";

import { PartitionedCard, PartitionedCardItem } from "./PartitionedCard";

function StationCard({
  stationId,
  guid,
}: {
  stationId?: string;
  guid?: string | null;
}): JSX.Element {
  const [station, setStation] = useState<WithDocRef<Station> | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const db = getFirestore();
    const collectionRef = collection(db, "stations");

    if (stationId) {
      getDoc(doc(collectionRef, stationId))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setStation({
              id: snapshot.id,
              docRef: snapshot.ref,
              ...snapshot.data(),
            } as WithDocRef<Station>);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (guid) {
      const nrelId = guid.replace("NREL:", "");
      const q = query(collectionRef, where("nrel_id", "==", nrelId));
      setLoading(true);
      getDocs(q)
        .then((snapshot) => {
          if (snapshot.size === 1) {
            const first = snapshot.docs[0];
            setStation({
              id: first.id,
              docRef: first.ref,
              ...first.data(),
            } as WithDocRef<Station>);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [stationId, guid]);

  return (
    <PartitionedCard title={"Station"} spacing={4} loading={loading}>
      <PartitionedCardItem title={"NREL"}>
        <Typography>
          {station?.nrel_id ?? guid?.replace("NREL:", "") ?? "Unknown"}
        </Typography>
      </PartitionedCardItem>

      <PartitionedCardItem title={"Name"}>
        <Typography>{station?.name ?? "Unknown"}</Typography>
      </PartitionedCardItem>

      <PartitionedCardItem title={"Network"}>
        <Typography>
          {station?.network ? station?.network?.name : "Unknown"}
        </Typography>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default StationCard;
