import { LaunchRounded } from "@mui/icons-material";
import { Link, Stack, Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { User } from "../../types/user.db";
import { formatPostalCode } from "../../util/stdlib";

function GeneralCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const phoneNumber = user.phone ? parsePhoneNumber(user.phone, "US") : null;
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${user?.postal_code}`;

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (user.timestamps.created_at as Timestamp)?.toDate();
  const lastSeen = (user.timestamps.last_seen as Timestamp)?.toDate();

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <PartitionedCard title={"General"} spacing={4}>
      {user.email && (
        <PartitionedCardItem title={"Email"}>
          <Typography>
            {user.email ? (
              <Link href={"mailto:" + user.email}>{user.email}</Link>
            ) : (
              "None"
            )}
          </Typography>
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Phone"}>
        <Typography>{phoneNumber?.formatNational() ?? "None"}</Typography>
      </PartitionedCardItem>

      <PartitionedCardItem title={"Address"}>
        <Link href={mapsUrl} target={"_blank"}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography>
              {user?.postal_code
                ? (user?.state ?? "CAN") +
                  ", " +
                  formatPostalCode(user?.postal_code)
                : "None"}
            </Typography>
            <LaunchRounded fontSize={"inherit"} />
          </Stack>
        </Link>
      </PartitionedCardItem>

      {user.photo_count && (
        <PartitionedCardItem title={"Photos"}>
          <Typography>{formatter.format(user.photo_count ?? 0)}</Typography>
        </PartitionedCardItem>
      )}

      {user.review_count && (
        <PartitionedCardItem title={"Reviews"}>
          <Typography>{formatter.format(user.review_count ?? 0)}</Typography>
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Joined"}>
        {createdAt ? (
          <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
            </Typography>
          </Tooltip>
        ) : (
          <Typography>None</Typography>
        )}
      </PartitionedCardItem>

      <PartitionedCardItem title={"Last seen"}>
        {lastSeen ? (
          <Tooltip title={format(lastSeen, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(lastSeen, { addSuffix: true })}
            </Typography>
          </Tooltip>
        ) : (
          <Typography>None</Typography>
        )}
      </PartitionedCardItem>

      <PartitionedCardItem title={"App version"}>
        <Typography>{user.last_user_agent || "None"}</Typography>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default GeneralCard;
